#services-board{
    width: 100%;
    min-height: 100vh;
}
.flip-card-front{
    position: relative; /* Asegura que el texto esté por encima del pseudo-elemento ::after */
    z-index: 2; /* Coloca el texto encima del pseudo-elemento ::after */
    margin: 0; /* Asegúrate de eliminar cualquier margen predeterminado */
    padding: 50px 0px; /* Asegúrate de eliminar cualquier relleno predeterminado */
    color: black;
    text-align: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    color: black;
    display: flex;
    height: 100%;
    flex-direction: row;
    /* align-items: center;
    justify-content:flex-start; */
}
.services-left-side{
    text-align: left;
    padding: 30px 0px 30px 80px;
    margin-top: -40px;
}
.services-left-side h1,.services-info h1 {
    font-weight: 900;
}
.services-steps {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Dos columnas de igual ancho */
}

.step > p{
    font-size: 14px !important;
    text-align: center;
}
.step-icon{
    width: 60px;
    height: 60px;
    font-size: 30px;
    background-color: rgb(210, 237, 210);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.services-contact-container{
    margin-top: 30px;
    margin-bottom: 30px;
}
.services-contact-container p{
    font-weight: 900 !important;
    font-size: 30px;
}
.carousel,
.carousel-inner,
.carousel-item {
    height: auto;
    overflow-y: visible !important; /* Permitir que el contenido sobresalga del carousel y sus ítems */
}
.carousel-item {
    margin-top: 50px; /* Ajusta este valor según sea necesario para hacer visible el contenido que se desplaza hacia arriba */
}
.eachService{
    display: flex;
    flex-direction: column;
    width: 85%;
    height: auto !important;
    margin: 0 auto;
    background-color: rgb(210, 237, 210);
    border-radius: 20px;
    overflow: visible !important;
}
.img-board {
    transform: translateY(-15%);
}
.service-img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 20px;
}
.services-info{
    height: 290px;
}
.flip-card-front h2 {
    font-size: 22px;
    margin-right: 30px;
}  
.flip-card-front p {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 24px;
    padding: 0px 25px 0px 25px;
}

.carousel-inner{
    width: 100%;
    color: black;
}
.carousel-indicators [data-bs-target] {
    height: 7px;
    /* border-radius: 50%; */
    background-color: black;
}
.carousel-indicators {
    position: relative !important;
}
@media (max-width: 768px){
    .col-md-6 {
        flex: 0 0 auto;
        width: auto !important;
    }
    .services-steps {
        display: grid;
        grid-template-columns: repeat(1, 1fr); /* Cambiar a una columna por fila */
    }
}

@media (width <= 992px) {
    .flip-card-front  {
      height: auto;
      text-align: center;
      flex-direction: column !important;
    }
    
    .eachService{
        width: 90%;
    }
    .services-left-side{
        padding: 30px !important;
    }
  }


@media (width <= 400px) {
    .services-info{
        height: 335px;
    }
}