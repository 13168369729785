#reviews{
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color:rgb(239, 237, 237);
}

.reviews-header{
    padding-top: 60px;
    text-align: center;
}
.reviews-header h4{
   color: rgb(111, 124, 110)
}
.reviews-header h1{
    font-weight: 900;
}
.reviews-body{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 25px;
}
/* css para cada carta de review */
.review-card{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.review-card-header{
    transform: translateY(50%);
}
.review-number{
    background-color: rgb(189, 232, 189);
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 800;
}
.review-card-body{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px 30px 0px ;
    background-color: white;
    padding: 40px 20px;
    /* background-color: rgb(234, 232, 232); */
    min-height:410px;
}
.review-card-body p{
    padding: 0px 20px;
    word-wrap: break-word;

}
.review-card-footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(-22%);
}
.review-card-footer p:last-child{
   color: gray
}
.rcf-img-container{
    background-color: white;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.rcf-img-decorator{
    width: 90%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid green;
    border-style: dashed ;
}
.review-card-footer img{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
}

@media (max-width: 1125px) {
    .reviews-body{
        grid-template-columns: 1fr;
    }
    .review-card-body{
        min-height: 280px;
    }
}
.indigoImg img{
    width: 20px;
}

@media (width<=576px) {
    .review-card-body{
        min-height: 360px;
    }
}