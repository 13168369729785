#introduction-board {
    min-height: 100vh;
    width: 100%;
    background-image: url("../img/backimg.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative; /* Añadido para asegurar el posicionamiento relativo */
}

#introduction-board::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; /* Cambiado de min-height a height */
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1; /* Asegura que el pseudo-elemento esté detrás del contenido */
}

.inner-introduction-board {
    position: relative; /* Asegura que el texto esté por encima del pseudo-elemento ::before */
    z-index: 2; /* Coloca el texto encima del pseudo-elemento ::before */
    margin: 0; /* Asegúrate de eliminar cualquier margen predeterminado */
    padding: 0; /* Asegúrate de eliminar cualquier relleno predeterminado */
    color: white;
    text-align: center;
}

.inner-introduction-board h1, .inner-introduction-board h3 {
    font-weight: 900;
    font-size: 3rem;
    line-height: 90px;
}

.inner-introduction-board h1 {
    font-size: 4.5rem;
    letter-spacing: 6px;
}

.contactBtn {
    border-radius: 15px;
    font-weight: 900;
    width: auto;
    padding: 5px 30px;
    letter-spacing: 2px;
}

@media (max-width: 992px) {
    .logo {
        position: absolute;
        width: 150px;
        height: 150px;
        top: -10px;
        right: -20px;
    }
    .inner-introduction-board h1, .inner-introduction-board h3 {
        font-weight: 900;
        font-size: 1.7rem;
        line-height: 50px;
    }
    .inner-introduction-board h1 {
        font-size: 2.5rem;
    }
    .intro-description {
        padding: 0px 35px;
        font-size: 1rem;
    }
    .contactBtn {
        letter-spacing: 0px;
    }
}

@media screen and (orientation: landscape) and (max-height: 630px) {
    .inner-introduction-board {
        width: 100%;
    }
    #introduction-board {
        min-height: 650px;
    }
}


